import { StateCreator } from "zustand";

export interface AuthState {
  user: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  login: (username: string, accessToken: string, refreshToken: string) => void;
  logout: () => void;
}

const getUserFromLocalStorage = (): Partial<AuthState> => {
  const userData = localStorage.getItem("user");
  if (!userData) return {};

  const { user, accessToken, refreshToken } = JSON.parse(userData);
  return { user, accessToken, refreshToken };
};

export const authSlice: StateCreator<AuthState> = (set) => ({
  user: null,
  accessToken: null,
  refreshToken: null,
  ...getUserFromLocalStorage(),
  login: (user, accessToken, refreshToken) => {
    set({ user, accessToken, refreshToken });
    localStorage.setItem(
      "user",
      JSON.stringify({ user, accessToken, refreshToken })
    );
  },
  logout: () => {
    set({ user: null, accessToken: null, refreshToken: null });
    localStorage.removeItem("user");
  },
});
