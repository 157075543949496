import React from "react";

import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import PageWidthContainer from "./PageWidthContainer";

const PageContent = styled.section`
  font-family: "Open Sans", "Nunito Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding: 48px 0;
  color: #333;

  h2 {
    font-size: 28px;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }

  h3 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  ul {
    list-style: disc inside;
    margin-bottom: 16px;
  }

  li {
    margin-bottom: 8px;
  }

  blockquote {
    border-left: 4px solid #ccc;
    padding-left: 16px;
    color: #666;
    margin-bottom: 16px;
    font-style: italic;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 16px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
  }

  pre {
    background-color: #f6f8fa;
    padding: 8px;
    overflow-x: auto;
    margin-bottom: 16px;
  }
`;

const PageTitle = styled.h2`
  font-size: 32px;
  line-height: 1;
  margin: 0 0 4px;
  display: inline-block;
  vertical-align: top;
`;

export const Page: React.FC<{
  title: string;
  rightAction?: React.ReactNode;
  children: React.ReactNode;
}> = ({ title, children, rightAction }) => {
  return (
    <article>
      <PageWidthContainer>
        <PageContent>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <PageTitle>{title}</PageTitle>
            {rightAction}
          </Stack>
          {children}
        </PageContent>
      </PageWidthContainer>
    </article>
  );
};
