const accent = {
  50: "#E0FDFF",
  100: "#BDF9FC",
  200: "#84ECF1",
  300: "#4FD3DA",
  400: "#30C0C8",
  500: "#23B3BB",
  600: "#04939A",
  700: "#017D85",
  800: "#006D73",
  900: "#004F53",
};

export default accent;
