import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import gray from "src/colors/gray";
import { IconButton, Popover } from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  border: `1px solid ${gray[200]}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  overflow: "hidden",
  marginLeft: 0,
  width: "100%",
  height: "27px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  fontSize: "14px",
  "& .MuiInputBase-input": {
    // vertical padding + font size from searchIcon
    height: "auto",
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Search: React.FC<{ foldout?: boolean }> = ({ foldout }) => {
  const searchField = (
    <Container>
      <SearchIconWrapper>
        <SearchIcon htmlColor={gray[500]} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Sök…"
        color="primary"
        inputProps={{ "aria-label": "search" }}
      />
    </Container>
  );

  return foldout ? (
    <PopupState variant="popover" popupId="search-field">
      {(popupState) => (
        <div>
          <IconButton {...bindTrigger(popupState)}>
            <SearchIcon htmlColor={gray[900]} />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            disableScrollLock
            style={{
              overflow: "visible",
            }}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            {searchField}
          </Popover>
        </div>
      )}
    </PopupState>
  ) : (
    searchField
  );
};

export default Search;
