import { create } from "zustand";
import { authSlice, AuthState } from "./authSlice";
import { userSlice, UserState } from "./userSlice";
import { statusSlice, StatusState } from "./statusSlice";

export type StoreState = AuthState & UserState & StatusState;

export const useStore = create<StoreState>((...a) => ({
  ...authSlice(...a),
  ...userSlice(...a),
  ...statusSlice(...a),
}));
