import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";

const ContentCard: React.FC<{
  title: string;
  text: string;
  color: string;
  image?: React.ReactNode;
  bottomAction: React.ReactNode;
}> = ({ title, text, color, image, bottomAction }) => {
  return (
    <Card
      sx={{
        borderRadius: 0,
        flex: 1,
        minWidth: "250px",
        maxWidth: "550px",
      }}
    >
      <CardMedia sx={{ backgroundColor: color, height: "116px" }}>
        <Stack direction={"row"} justifyContent={"center"}>
          {image}
        </Stack>
      </CardMedia>
      <CardContent
        sx={{
          textAlign: "center",
          maxWidth: "400px",
          margin: "0 auto",
          height: "auto",
          "& .MuiLink-root": {
            fontSize: 16,
            fontWeight: 600,
          },
        }}
      >
        <Stack
          spacing={2}
          pt={2}
          pb={4}
          px={2}
          height={"100%"}
          justifyContent={"space-between"}
        >
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1" fontSize={16}>
            {text}
          </Typography>
          {bottomAction}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ContentCard;
