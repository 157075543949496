import { User } from "@api/types";
import { StateCreator } from "zustand";

export interface UserState {
  fetchingUsers: boolean;
  users: User[];
  setFetchingUsers: (fetchingUsers: boolean) => void;
  setUsers: (users: User[]) => void;
  setUser: (user: User) => void;
  removeUser: (id: number) => void;
}

export const userSlice: StateCreator<UserState> = (set) => ({
  fetchingUsers: false,
  users: [],
  setFetchingUsers: (fetchingUsers) => set({ fetchingUsers }),
  setUsers: (users) => set({ users }),
  setUser: (user) => {
    set((state) => {
      const existingUser = state.users.find((u) => u.id === user.id);
      if (existingUser) {
        return {
          users: state.users.map((u) => (u.id === user.id ? user : u)),
        };
      } else {
        return {
          users: [...state.users, user],
        };
      }
    });
  },
  removeUser: (id) => {
    set((state) => {
      return {
        users: state.users.filter((u) => u.id !== id),
      };
    });
  },
});
