import { StateCreator } from "zustand";

export interface StatusState {
  version: string;
  previousVersion: string;
  healthy: boolean;
  gettingStatus: boolean;
  setVersion: (version: string) => void;
  setPreviousVersion: (previousVersion: string) => void;
  setHealthy: (healthy: boolean) => void;
  setGettingStatus: (gettingStatus: boolean) => void;
}

export const statusSlice: StateCreator<StatusState> = (set) => ({
  version: "",
  previousVersion: "",
  healthy: false,
  gettingStatus: false,
  setVersion: (version: string) => set({ version }),
  setPreviousVersion: (previousVersion: string) => set({ previousVersion }),
  setHealthy: (healthy: boolean) => set({ healthy }),
  setGettingStatus: (gettingStatus: boolean) => set({ gettingStatus }),
});
