export function formatDateTime(datetime: string): string {
  const date = new Date(datetime);
  const formattedDate = date.toISOString().slice(0, 10);
  const formattedTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${formattedDate} ${formattedTime}`;
}
/**
 * Formats a datetime period concisely according to Swedish standards.
 * If the dates are within the current year, the year is omitted.
 * @param {String} start - The start date and time.
 * @param {String} end - The end date and time.
 * @returns {string} - The formatted datetime period.
 */
export function formatDatetimePeriod(startStr: string, endStr: string): string {
  const start = new Date(startStr);
  const end = new Date(endStr);
  const now = new Date();
  const currentYear = now.getFullYear();
  const optionsDateWithoutYear: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
  };
  const optionsDateWithYear: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const startDate = start.toLocaleDateString(
    "sv-SE",
    start.getFullYear() === currentYear
      ? optionsDateWithoutYear
      : optionsDateWithYear
  );
  const endDate = end.toLocaleDateString(
    "sv-SE",
    end.getFullYear() === currentYear
      ? optionsDateWithoutYear
      : optionsDateWithYear
  );
  const startTime = start.toLocaleTimeString("sv-SE", optionsTime);
  const endTime = end.toLocaleTimeString("sv-SE", optionsTime);

  if (start.getFullYear() === end.getFullYear()) {
    if (startDate === endDate) {
      return `${startDate} ${startTime}-${endTime}`;
    } else {
      return `${startDate} ${startTime} - ${endDate} ${endTime}`;
    }
  } else {
    return `${startDate} ${startTime} - ${endDate} ${endTime}`;
  }
}