import React from "react";

import { CircularProgress, Paper, Stack, Typography } from "@mui/material";

export const SubPage: React.FC<{
  title: string;
  rightAction?: React.ReactNode;
  loading?: boolean;
  children: React.ReactNode;
}> = ({ title, loading, rightAction, children }) => {
  return (
    <Paper>
      <Stack spacing={1} p={1}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems="center"
          spacing={1}
        >
          <Stack direction={"row"} spacing={1} alignItems="center">
            <Typography variant="h6">{title}</Typography>
            {loading && <CircularProgress size={20} />}
          </Stack>
          {rightAction}
        </Stack>
        {children}
      </Stack>
    </Paper>
  );
};
