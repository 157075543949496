import { useStore } from "@feat/common/store/store";
import apiClient from "@api/client";
import { useCallback, useEffect, useRef } from "react";

const useStatus = () => {
  const {
    gettingStatus,
    version,
    previousVersion,
    healthy,
    setGettingStatus,
    setVersion,
    setPreviousVersion,
    setHealthy,
  } = useStore((state) => ({
    version: state.version,
    previousVersion: state.previousVersion,
    healthy: state.healthy,
    gettingStatus: state.gettingStatus,
    setGettingStatus: state.setGettingStatus,
    setVersion: state.setVersion,
    setPreviousVersion: state.setPreviousVersion,
    setHealthy: state.setHealthy,
  }));

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const fetchStatus = useCallback(async () => {
    setGettingStatus(true);
    try {
      const response = await apiClient.get("health");
      let versionChanged = false;
      if (previousVersion === "") {
        setPreviousVersion(response.data.version);
      } else if (previousVersion !== response.data.version) {
        versionChanged = true;
      }
      setVersion(response.data.version);
      setHealthy(response.data.healthy);
      return versionChanged;
    } catch (error) {
      console.error("Failed to fetch status", error);
      return false;
    } finally {
      setGettingStatus(false);
    }
  }, [
    setGettingStatus,
    previousVersion,
    setVersion,
    setHealthy,
    setPreviousVersion,
  ]);

  const startTimer = useCallback(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(async () => {
        const versionChanged = await fetchStatus();
        if (versionChanged && timerRef.current) {
          clearInterval(timerRef.current);
          timerRef.current = null;
        }
      }, 5000);
    }
  }, [fetchStatus]);

  useEffect(() => {
    fetchStatus().then((versionChanged) => {
      if (!versionChanged) {
        startTimer();
      }
    });

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [fetchStatus, startTimer]);

  return {
    gettingStatus,
    version,
    previousVersion,
    healthy,
  };
};

export default useStatus;
