const orange = {
  100: "#FFE6C9",
  200: "#FFd8B4",
  300: "#F5B373",
  400: "#F08A27",
  500: "#D97D21",
  600: "#A45E18",
  700: "#5934OE",
  800: "#3B2209",
};

export default orange;
