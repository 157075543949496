import { SubPage } from "@comp/SubPage";
import useUser from "@feat/user/userService";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ManageUsersRoute: React.FC = () => {
  const { users, fetchingUsers, fetchUsers, createUser, updateUser } =
    useUser();

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [editUserID, setEditUserID] = useState<number | null>(null);
  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserPassword, setEditUserPassword] = useState("");
  const [
    editUserReceiveEmailNotifications,
    setEditUserReceiveEmailNotifications,
  ] = useState(false);
  const [userSaving, setUserSaving] = useState(false);

  const handleClickSave = () => {
    setUserSaving(true);
    if (editUserID !== null) {
      updateUser(
        editUserID,
        editUserEmail,
        editUserPassword,
        editUserReceiveEmailNotifications
      )
        .then(() => {
          resetForm();
        })
        .catch((error) => {
          console.error("Failed to update user", error);
        })
        .finally(() => {
          setUserSaving(false);
        });
    } else {
      createUser(
        editUserEmail,
        editUserPassword,
        editUserReceiveEmailNotifications
      )
        .then(() => {
          resetForm();
        })
        .catch((error) => {
          console.error("Failed to create user", error);
        })
        .finally(() => {
          setUserSaving(false);
        });
    }
  };

  const editUser = (id: number) => {
    const user = users.find((u) => u.id === id);
    if (user) {
      setEditUserID(user.id);
      setEditUserEmail(user.username);
      setEditUserReceiveEmailNotifications(user.email_notifications);
      setShowEditUserModal(true);
    }
  };

  const resetForm = () => {
    setEditUserEmail("");
    setEditUserPassword("");
    setEditUserReceiveEmailNotifications(false);
    setShowEditUserModal(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const columns: GridColDef[] = [
    { field: "username", headerName: "Användarnamn", flex: 1 },
    {
      field: "email_notifications",
      headerName: "Får notiser via e-post",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Checkbox checked={params.value} disabled />
      ),
    },
    {
      field: "actions",
      headerName: "Hantering",
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton
            onClick={() => {
              editUser(params.row.id);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton>
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <SubPage
        loading={fetchingUsers}
        title="Användare (aka admins)"
        rightAction={
          <IconButton onClick={() => setShowEditUserModal(true)}>
            <Add />
          </IconButton>
        }
      >
        <Box height={400}>
          <DataGrid rows={users} columns={columns} autoHeight />
        </Box>
      </SubPage>
      <Modal
        open={showEditUserModal}
        onClose={() => setShowEditUserModal(false)}
      >
        <Card sx={style}>
          <CardHeader
            title={
              editUserID !== null ? "Redigera användare" : "Lägg till användare"
            }
          />
          <CardContent>
            <FormGroup>
              <Stack spacing={2}>
                <FormControl required>
                  <TextField
                    label="Användarnamn"
                    value={editUserEmail}
                    onChange={(e) => setEditUserEmail(e.target.value)}
                    aria-describedby="Ange en e-postadress"
                  />
                </FormControl>
                <FormControl required={editUserID === null}>
                  <TextField
                    label="Lösenord"
                    type="password"
                    value={editUserPassword}
                    onChange={(e) => setEditUserPassword(e.target.value)}
                    aria-describedby="Ange ett lösenord"
                  />
                  {editUserID !== null && (
                    <FormHelperText>
                      Lämna fältet tomt för att inte ändra lösenordet
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editUserReceiveEmailNotifications}
                        onChange={(e) =>
                          setEditUserReceiveEmailNotifications(e.target.checked)
                        }
                      />
                    }
                    label="Ta emot e-postnotifikationer"
                  />
                </FormControl>
              </Stack>
            </FormGroup>
          </CardContent>
          <CardActions>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              width="100%"
            >
              <Button variant="contained" color="secondary" onClick={resetForm}>
                Avbryt
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickSave}
                disabled={userSaving}
              >
                {userSaving ? <CircularProgress size={24} /> : "Spara"}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default ManageUsersRoute;
