import styled from "@emotion/styled";
import { Card, CardContent, CardHeader } from "@mui/material";
import PageWidthContainer from "./PageWidthContainer";
import Health from "./Health";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const Section = styled(Card)`
  padding: 20px;
  flex: 1;
  min-width: 200px;
`;

export const Footer: React.FC = () => {
  return (
    <footer>
      <PageWidthContainer>
        <Container>
          <Section>
            <CardHeader title="Version" />
            <CardContent>
              <Health />
            </CardContent>
          </Section>
          <Section>
            <CardHeader title="Footer 2" />
            <CardContent>Det här är en annan footer.</CardContent>
          </Section>
        </Container>
      </PageWidthContainer>
    </footer>
  );
};
