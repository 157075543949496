import { SubPage } from "@comp/SubPage";
import { Bookable, Booking, Venue, Course } from "@api/types";
import apiClient from "@api/client";
import {
  formatDateTime,
  formatDatetimePeriod,
} from "@feat/common/utils/nice-date";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

const ListBookingsRoute: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);
  const [bookables, setBookables] = useState<Bookable[]>([]);
  const [bookings, setBookings] = useState<Booking[]>([]);

  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingVenues, setLoadingVenues] = useState(true);
  const [loadingBookables, setLoadingBookables] = useState(true);
  const [loadingBookings, setLoadingBookings] = useState(true);

  const refreshData = () => {
    setLoadingCourses(true);
    apiClient
      .get("courses")
      .then((response) => response.data)
      .then((data: Course[]) => setCourses(data))
      .finally(() => setLoadingCourses(false));

    setLoadingVenues(true);
    apiClient
      .get("venues")
      .then((response) => response.data)
      .then((data: Venue[]) => setVenues(data))
      .finally(() => setLoadingVenues(false));

    setLoadingBookables(true);
    apiClient
      .get("bookables")
      .then((response) => response.data)
      .then((data: Bookable[]) => setBookables(data))
      .finally(() => setLoadingBookables(false));

    setLoadingBookings(true);
    apiClient
      .get("bookings")
      .then((response) => response.data)
      .then((data: Booking[]) => setBookings(data))
      .finally(() => setLoadingBookings(false));
  };

  useEffect(() => {
    refreshData();
  }, []);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Namn", flex: 1 },
    { field: "email", headerName: "E-post", flex: 1 },
    { field: "phone", headerName: "Telefon", flex: 1 },
    { field: "participants", headerName: "Deltagare", type: "number", flex: 1 },
    { field: "course", headerName: "Kurs", flex: 1 },
    { field: "venue", headerName: "Plats", flex: 1 },
    { field: "date", headerName: "Datum", flex: 1 },
  ];

  const rows = bookings.map((booking) => {
    const bookable = bookables.find((b) => b.id === booking.bookable_id);
    const course = bookable
      ? courses.find((c) => c.id === bookable.course_id)
      : null;
    const venue = bookable
      ? venues.find((v) => v.id === bookable.venue_id)
      : null;

    return {
      id: booking.id,
      name: booking.name,
      email: booking.email,
      phone: booking.phone,
      participants: booking.participants,
      course: course ? course.title : "Kunde inte hitta kurs",
      venue: venue ? venue.name : "Kunde inte hitta plats",
      date: bookable
        ? `${formatDatetimePeriod(bookable.start_date, bookable.end_date)}`
        : "Kunde inte hitta datum",
    };
  });

  return (
    <SubPage title="Gjorda bokningar">
      {loadingBookings ||
      loadingBookables ||
      loadingCourses ||
      loadingVenues ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box height={600} width="100%">
          <DataGrid rows={rows} columns={columns} pageSizeOptions={[5, 10]} />
        </Box>
      )}
    </SubPage>
  );
};

export default ListBookingsRoute;
