import useAuth from "@feat/user/authService";
import {
  FormControl,
  Stack,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup,
} from "@mui/material";
import { useState } from "react";

const LoginForm: React.FC = () => {
  const { loginHandler } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onRememberChange = () => {
    setRemember(!remember);
  };

  const onLogin = async () => {
    setLoading(true);
    setError(null);
    try {
      await loginHandler(username, password, remember);
    } catch (err) {
      setError("Login failed. Please check your credentials and try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormGroup>
      <Stack spacing={2}>
        <FormControl>
          <TextField
            label="Användarnamn"
            value={username}
            onChange={onUsernameChange}
            required
            disabled={loading}
          />
        </FormControl>
        <FormControl>
          <TextField
            label="Lösenord"
            type="password"
            value={password}
            onChange={onPasswordChange}
            required
            disabled={loading}
          />
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={remember} onChange={onRememberChange} />}
          label="Kom ihåg mig"
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <FormControl>
          <Button variant="contained" onClick={onLogin} disabled={loading}>
            {loading ? "Logging in..." : "Logga in"}
          </Button>
        </FormControl>
      </Stack>
    </FormGroup>
  );
};

export default LoginForm;
