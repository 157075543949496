import React from "react";
import PageWidthContainer from "@comp/PageWidthContainer";
import Hero from "@comp/Hero";
import { Link, Stack, Typography } from "@mui/material";
import ContentCard from "@comp/ContentCard";
import { ReactComponent as TalaImage } from "@assets/content-card-header-tala.svg";

const HomeRoute: React.FC = () => {
  return (
    <>
      <PageWidthContainer fullWidth>
        <Hero title="Retorik - så får du andra att lyssna">
          <Typography>
            Retorik handlar om att kommunicera effektivt och är en färdighet du
            kan öva upp.
          </Typography>
          <Link href="/booking">Läs mer om våra retorikkurser</Link>
        </Hero>
      </PageWidthContainer>
      <PageWidthContainer>
        <Typography variant="h4" textAlign="center">
          Våra utbildningsområden
        </Typography>
        <Stack
          direction={"row"}
          gap={2}
          mb={2}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          <ContentCard
            title="Tala och presentera"
            color="#7A95D8"
            text="Bli säker framför publik med våra utbildningar i retorik och presentationsteknik"
            image={<TalaImage />}
            bottomAction={
              <Link href="/courses">
                Plats för länkar till utvalda utbildningar
              </Link>
            }
          />
          <ContentCard
            title="Skrivutbildningar"
            color="#04939A"
            text="För dig som skriver i ditt arbete - vi har utbildningar som vässar din kompetens "
            bottomAction={
              <Link href="/courses">
                Plats för länkar till utvalda utbildningar
              </Link>
            }
          />
          <ContentCard
            title="Möten & workshops"
            text="Lär dig hålla bättre och effektivare möten och workshops med våra populära utbildningar"
            color={"#E864A6"}
            bottomAction={
              <Link href="/courses">
                Plats för länkar till utvalda utbildningar
              </Link>
            }
          />
        </Stack>
      </PageWidthContainer>
    </>
  );
};

export default HomeRoute;
