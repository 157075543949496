import theme from "src/theme";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";

const Hero: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  const widerThanPage = useMediaQuery(theme.breakpoints.up("lg"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  let textFontSize = "2vw";
  let titleFontSize = "4vw";

  if (mobile) {
    textFontSize = "16px";
    titleFontSize = "32px";
  } else if (widerThanPage) {
    textFontSize = "20px";
    titleFontSize = "40px";
  }

  return (
    <Box
      sx={{
        position: "relative",
        aspectRatio: mobile ? "auto" : "16 / 6",
        "& .MuiTypography-body1": {
          fontFamily: "EB Garamond",
          fontSize: textFontSize,
        },
        "& .MuiLink-root": {
          fontFamily: "EB Garamond",
          fontSize: textFontSize,
        },
        background: 'url("/hero/bubbles.svg")',
        backgroundSize: "cover",
      }}
    >
      <Stack
        sx={{
          position: mobile ? "relative" : "absolute",
          top: mobile ? "" : "37%",
          left: mobile ? "" : "12%",
          padding: mobile ? "2rem" : "0",
        }}
        gap={2}
      >
        <Typography variant="h3" fontSize={titleFontSize}>
          {title}
        </Typography>
        <Stack width={"55%"} gap={1.5}>
          {children}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Hero;
