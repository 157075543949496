import { createTheme } from "@mui/material";
import gray from "./colors/gray";
import accent from "./colors/accent";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { forwardRef } from "react";
import orange from "./colors/orange";
import indigo from "./colors/orange";

export const pageWidth = 1060;

const headings = {
  fontFamily: "EB Garamond",
  fontWeight: 600,
};

const body = {
  fontFamily: "Open Sans",
  fontSize: 16,
};

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  palette: {
    text: {
      primary: gray[900],
    },
    primary: {
      main: orange[300],
    },
    secondary: {
      main: accent[900],
    },
  },
  typography: {
    fontFamily: "Open Sans",
    h1: headings,
    h2: headings,
    h3: headings,
    h4: headings,
    h5: headings,
    h6: headings,
    subtitle1: headings,
    subtitle2: headings,
    body1: body,
    body2: body,
    caption: body,
    button: {
      ...body,
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: pageWidth,
      xl: 1920,
    },
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          ...body,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
          fontSize: 20,
          color: gray[900],
          textDecoration: "underline",
          "&:hover": {
            color: indigo[700],
          },
        },
      },
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

export default theme;
