import { Course } from "@api/types";

const open = [
  "Presentationsteknik",
  "Våga tala inför grupp",
  "Presentation skills (in English)",
  "Retorikcoachning",
  "Fördjupning och övning",
  "Retorikens fula knep",
  "Kortkurs i yrkesskrivande",
  "Skrivande i arbetslivet 1",
  "Skrivande i arbetslivet 2",
  "Skriva på myndighet",
  "Skrivcoachning",
  "Mötesteknik",
  "Facilitering och workshops",
];

const business = [
  "Praktisk retorik",
  "Retorikens fula knep",
  "Presentationsteknik",
  "Rhetoric in English",
  "Argumentationsteknik",
  "Skrivande i arbetslivet",
  "Skriva myndighetstexter",
  "Effektiva möten",
  "Förhandlingsteknik",
  "Svåra samtal",
  "Workshops",
];

const conference = [
  "Argumentationsteknik",
  "Att göra en pudel",
  "Härskartekniker",
  "Debattävling",
  "Workshops och kreativa möten",
  "PowerPoint och presentationer",
  "Praktisk övningskurs",
  "Presentationsteknik",
  "Retorikens fula knep",
  "Retorik för kvinnor",
  "Talängslan och nervositet",
  "Rätt och fel i språket",
  "Skriv bättre mejl",
  "Skriva i sociala medier",
  "Skrivregler för 2020-talet",
  "Språklagen - så funkar den",
];

const web = [
  "Presentationsteknik",
  "Presentera på webb, 2 h, liten grupp",
  "Presentera på webb 1 h webinar",
  "Presentation skills (in English)",
  "Retorikens fula knep",
  "Retorikcoachning – på distans",
  "Workshops och facilitering",
  "Mötesteknik, effektiva möten",
  "Att skriva bättre på jobbet",
  "Skriv utan fel och missförstånd",
  "Skriva på myndighet",
  "Skrivcoachning/skrivhandledning",
];

export const courses: Course[] = [
  ...open.map((title, i) => ({
    id: i,
    title,
    category: "Öppna utbildningar",
    description: "Description",
    is_remote: false,
    price: 1000,
    min_participants: 1,
    max_participants: 10,
    prerequisites: "Prerequisites",
  })),
  ...business.map((title, i) => ({
    id: i,
    title,
    category: "Företagsutbildningar",
    description: "Description",
    is_remote: false,
    price: 1000,
    min_participants: 1,
    max_participants: 10,
    prerequisites: "Prerequisites",
  })),
  ...web.map((title, i) => ({
    id: i,
    title,
    category: "Webbutbildningar",
    description: "Description",
    is_remote: true,
    price: 1000,
    min_participants: 1,
    max_participants: 10,
    prerequisites: "Prerequisites",
  })),
  ...conference.map((title, i) => ({
    id: i,
    title,
    category: "Föreläsningar",
    description: "Description",
    is_remote: false,
    price: 1000,
    min_participants: 1,
    max_participants: 10,
    prerequisites: "Prerequisites",
  })),
];
