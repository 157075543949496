import styled from "@emotion/styled";
import AdminRoute from "@feat/admin/AdminRoute";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { NoMatch } from "./features/404/NoMatchRoute";
import { Footer } from "./features/common/components/Footer";
import { Header } from "./features/common/components/Header";
import HomeRoute from "./features/home/HomeRoute";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
import { courses } from "./stories/courses";
import { Page } from "@comp/Page";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const pages = [
  {
    name: "Kontakt",
    url: "/contact",
    element: (
      <Page title="Kontakt">
        <p>Kommer snart</p>
      </Page>
    ),
  },
  {
    name: "Vi som utbildar",
    url: "/teachers",
    element: (
      <Page title="Vi som utbildar">
        <p>Kommer snart</p>
      </Page>
    ),
  },
  {
    name: "Tidigare uppdrag",
    url: "/references",
    element: (
      <Page title="Tidigare uppdrag">
        <p>Kommer snart</p>
      </Page>
    ),
  },
  {
    name: "Orter",
    url: "/locations",
    element: (
      <Page title="Orter">
        <p>Kommer snart</p>
      </Page>
    ),
  },
];

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Container>
          <Header pages={pages} courses={courses} />
          <Routes>
            <Route path="/" element={<HomeRoute />} />
            {pages.map((page) => (
              <Route key={page.url} path={page.url} element={page.element} />
            ))}
            <Route path="/admin/*" element={<AdminRoute />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
          <Footer />
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
